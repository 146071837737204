export function selectSearchPanes(table) {
    $('#button-select-all').click(function () {
        let button = $('#button-create-jobs');
        let count = 0;

        $(this).removeClass('btn-secondary').addClass('btn-primary disabled');
        $('#data_sources_table tbody tr').addClass('selected');
        $('#ajax-message').html('');

        $('#data_sources_table .count-job:not(:first)').each(function (index) {
            count += parseInt($(this).text());
        });

        button.html(`Create ${count} Job(s)`).data('count', count)
    });

    $('#button-clear-all').click(function () {
        $('#data_sources_table tbody tr').removeClass('selected');
        $('#button-select-all').removeClass('btn-primary disabled').addClass('btn-secondary');
        $('#button-create-jobs').html(`Create 0 Job(s)`).data('count', 0)
    });

    $('#data_sources_table tbody').on('click', 'tr', function () {
        $('#button-select-all').removeClass('btn-primary disabled').addClass('btn-secondary');
        $(this).toggleClass('selected');
        $('#ajax-message').html('');

        let button = $('#button-create-jobs');
        let count = button.data('count');
        let row = $(this).closest("tr");
        let nbr = parseInt(row.find(".count-job").text());

        if ($(this).hasClass('selected')) {
            count = count + nbr;
        } else {
            count = count - nbr;
        }

        button.html(`Create ${count} Job(s)`).data('count', count)
    });

    $('#button-create-jobs').click(function () {

        let selected = $('#data_sources_table .selected')

        if (selected.length >= 1) {
            let onlyAttribution;
            let arr = [];

            table.rows('.selected').data().each(function (dt) {
                arr.push(dt['data_for_job']);
            });

            onlyAttribution = !!$('#only-attribution').is(':checked');

            $.ajax({
                type: "POST",
                url: "/admin/space_job/create-data-sources-jobs",
                data: {arr: JSON.stringify(arr), onlyAttribution: onlyAttribution}
            });

            selected.removeClass('selected');
            $('#button-create-jobs').html(`Create 0 Job(s)`).data('count', 0);
            $('#button-select-all').removeClass('btn-primary disabled').addClass('btn-secondary');

        } else {
            $('#ajax-message').html('Please select at least one row')
        }
    });
}