// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// CSS
import 'scss/application'
import 'scss/templates'

// JS
import('js/notify.min')
import('js/site')
import('js/setup')
import('js/template')
import('js/templates-type')
import('js/multi-select')
import('js/data_sources')

// Images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
ActiveStorage.start()
