import consumer from "./consumer"

consumer.subscriptions.create("HomeChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        let upInterval = setTimeout(function () {
            window.location.href = "https://" + data['space'] + ".api.adloop.co";
        }, 5000)
    }
});
