import consumer from "./consumer"
import {selectSearchPanes} from "../js/data_sources"

let WS;
let spaceTable;
let spacesTable;
let errorsTable;
let dataSourcesTable;

$(document).ready(function () {

    $("#space-selector").change(function () {
        if (WS !== undefined) {
            WS.unsubscribe();
        }
        createWebSocket($(this).val());
    });

    const spaceList = $("#space_list_table");
    if (spaceList.length) {
        createWebSocket('all');
    }

    const space_slug = $("#table_space_data");
    if (space_slug.length) {
        createWebSocket(space_slug.val());
    }

    const list_errors = $("#list_errors");
    if (list_errors.length) {
        createWebSocket('errors');
    }

    const data_sources_table = $("#data_sources_table");
    if (data_sources_table.length) {
        createWebSocket('data_sources_table');
    }

});

function createWebSocket(space_slug) {
    WS = consumer.subscriptions.create({channel: "AdminChannel", room: space_slug}, {
        connected() {
        },

        disconnected() {
        },

        received(data) {

            if (space_slug === 'all') {
                if (spacesTable) {
                    updateTable(spacesTable, data['space_data'])
                } else {
                    spacesTable = fillSpacesListTemplate(data);
                }
            } else if (space_slug === 'errors') {
                if (errorsTable) {
                    updateTable(errorsTable, data['errors_data'])
                } else {
                    errorsTable = fillErrorsTemplate(data);
                }
            } else if (space_slug === 'data_sources_table') {
                if (dataSourcesTable) {
                    updateTable(dataSourcesTable, data['data_sources_data'])
                } else {
                    dataSourcesTable = fillDataSourcesTable(data);
                }
            } else {
                if (spaceTable) {
                    updateTable(spaceTable, data['data_set_data'])
                } else {
                    spaceTable = fillSpaceDataTemplate(data);
                }
            }
        }
    });
}

function updateTable(table, data) {
    table.clear();
    table.rows.add(data);
    table.draw();
}

function fillSpacesListTemplate(data) {
    return $('#space_list_table').DataTable({
        dom: 'Bfrtip',
        buttons: [
            'copyHtml5',
            'csvHtml5',
            {
                extend: 'pdfHtml5',
                orientation: 'landscape',
                pageSize: 'LEGAL'
            },
            {
                extend: 'excelHtml5',
                autoFilter: true,
                sheetName: 'Space list data'
            }
        ],
        orderCellsTop: true,
        fixedHeader: true,
        scrollX: true,
        fixedColumns: {
            left: 1
        },
        data: data['space_data'],
        columns: [
            {data: 'name'},
            {data: 'space_admin'},
            {data: 'plan'},
            {data: 'expiration'},
            {data: 'revenue'},
            {data: 'commitment'},
            {data: 'is_premium'},
            {data: 'country'},
            {data: 'last_sign_in'},
            {data: 'sign_in_count'}

        ],
        columnDefs: [
            {
                targets: "_all",
                className: 'dt-body-center'
            },
            {
                targets: "_all",
                className: 'dt-head-left'
            },
            {
                targets: "_all",
                className: 'dt-body-nowrap'
            }
        ]
    });
}

function fillDataSourcesTable(data) {

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const time = moment(data['time']).tz(tz);
    const tableUpdatedAt = 'Table updated at: ' + time.format('HH:mm');
    $('.table-updated-at').text(tableUpdatedAt);

    let table = $('#data_sources_table').DataTable({
        dom: 'Plrtip',
        data: data['data_sources_data'],
        columns: [
            {data: 'space'},
            {data: 'data_set_name'},
            {data: 'data_set_usages'},
            {data: 'data_source'},
            {data: 'data_for_job'},
            {data: 'data_set_usages_count'}
        ],
        searchPanes: {
            layout: 'columns-1',
            order: ["DataSource", "Space"],
            orderable: false,
            collapse: false,
            dtOpts: {
                select: {
                    style: 'multi'
                }
            }
        },
        orderCellsTop: true,
        fixedHeader: true,
        scrollX: true,
        fixedColumns: {
            left: 1
        },
        columnDefs: [
            {
                searchPanes: {
                    show: true
                },
                targets: [0, 3]
            },
            {
                searchPanes: {
                    show: false
                },
                targets: [1, 2, 4, 5]
            },
            {
                targets: [4, 5],
                className: 'd-none'
            },
            {
                targets: [5],
                className: 'count-job'
            },
            {
                targets: "_all",
                className: 'dt-body-center'
            },
            {
                targets: "_all",
                className: 'dt-head-center'
            },
            {
                targets: "_all",
                className: 'dt-body-nowrap'
            }
        ]
    });
    $("div.dtsp-verticalPanes").append(table.searchPanes.container());
    selectSearchPanes(table);
    return table
}

function fillSpaceDataTemplate(data) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const time = moment(data['time']).tz(tz);
    const tableUpdatedAt = 'Table updated at: ' + time.format('HH:mm');
    $('.table-updated-at').text(tableUpdatedAt);

    return $('#space_data_table').DataTable({
        dom: 'Bfrtip',
        buttons: [
            'copyHtml5',
            'csvHtml5',
            {
                extend: 'pdfHtml5',
                orientation: 'landscape',
                pageSize: 'LEGAL'
            },
            {
                extend: 'excelHtml5',
                autoFilter: true,
                sheetName: 'Space data'
            }
        ],
        orderCellsTop: true,
        fixedHeader: true,
        scrollX: true,
        fixedColumns: {
            left: 1
        },
        data: data['data_set_data'],
        columns: [
            {data: 'data_set_name'},
            {data: 'users'},
            {data: 'nb_sources'},
            {data: 'data_set_usages'},
            {data: 'nb_of_notif'},
            {data: 'notif_status'},
            {data: 'last_sent_notif'},
            {data: 'nb_of_exports'},
            {data: 'last_sent_export'},
            {data: 'nb_saved_reports'}
        ],
        columnDefs: [
            {
                targets: "_all",
                className: 'dt-body-center'
            },
            {
                targets: "_all",
                className: 'dt-head-left'
            },
            {
                targets: "_all",
                className: 'dt-body-nowrap'
            }
        ]
    });
}

function fillErrorsTemplate(data) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const time = moment(data['time']).tz(tz);
    const tableUpdatedAt = 'Table updated at: ' + time.format('HH:mm');
    $('.table-updated-at').text(tableUpdatedAt);
    return $('#list_errors').DataTable({
        dom: 'Bfrtip',
        buttons: [
            'copyHtml5',
            'csvHtml5',
            {
                extend: 'pdfHtml5',
                orientation: 'landscape',
                pageSize: 'LEGAL'
            },
            {
                extend: 'excelHtml5',
                autoFilter: true,
                sheetName: 'Errors data'
            }
        ],
        orderCellsTop: true,
        fixedHeader: true,
        scrollX: true,
        fixedColumns: {
            left: 1
        },
        order: [[2, 'desc']],
        data: data['errors_data'],
        columns: [
            {data: 'space'},
            {data: 'data_set'},
            {data: 'nb_errors'},
            {data: 'nb_data_set_usage_errors'},
            {data: 'data_set_usage_errors'},
            {data: 'nb_notification_errors'},
            {data: 'notification_errors'}
        ],
        columnDefs: [
            {
                targets: "_all",
                className: 'dt-body-center'
            },
            {
                targets: "_all",
                className: 'dt-head-left'
            },
            {
                targets: "_all",
                className: 'dt-body-nowrap'
            }
        ]
    });
}

